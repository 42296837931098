import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="GNSS systémy a jejich složení" />
    <h1>GNSS systémy a jejich složení</h1>

    <p><strong>GNSS </strong>je zkratkou pro <strong>globální navigační satelitní systém</strong>. Ten byl vytvořen v&nbsp;roce 1973 a od té doby prochází kontinuálním vývojem. Jako první systém GNSS primárně sloužil NAVSTAR GPS, který byl vytvořen armádou USA.</p>
<p>GNSS pro veřejnou sféru slouží především jako navigace v&nbsp;automobilech, či v&nbsp;mobilních telefonech a jiných zařízeních. Systémy lze využívat i v&nbsp;geodézii, zejména inženýrské a technické.</p>
<p>Mezi nejznámější GNSS patří bezesporu americký <strong>NAVSTAR GPS</strong>, dále ruský <strong>GLONASS</strong>, čínský <strong>Beidou</strong>, evropský <strong>Galileo</strong>, japonský <strong>Quasi-Zenith QZSS</strong> a indický regionální <strong>IRNSS</strong>.</p>
<hr /><h2>Složení systému GNSS</h2>
<p>Systém GNSS se skládá z těchto základních segmentů:</p>
<ul>
<li><strong>kosmický segment</strong></li>
<li><strong>řídící segment</strong></li>
<li><strong>uživatelský segment</strong></li>
<li><strong>podpůrný segment</strong></li>
</ul>
<h3>Kosmický segment</h3>
<p>Kosmický segment se skládá z aktivních umělých družic, které obíhají Zemi po téměř kruhových dráhách v několika oběžných rovinách, které jsou skloněny vůči rovníku o 55 až 65&nbsp;°. Satelity obíhají po MEO (Medium Earth Orbit), tedy po středně vysokých drahách 19 až 24 tisíc km nad Zemí.</p>
<p>Mezi základní vybavení družice patří generátor frekvence (atomové hodiny, oscilátor, frekvenční standard), antény vysílací i přijímací, stabilizační setrvačníky, solární panely, baterie, raketové motorky, odrazné hranoly a další.</p>
<h3>Řídící segment</h3>
<p>Řídící segment je zodpovědný za řízení a monitorování satelitů. Nastavuje přesné efemeridy (oběžné dráhy) a uchovává časový normál (pozemní atomové hodiny). Zahrnuje hlavní řídící stanice a monitorovací stanice. Koriguje dráhy satelitů a navazuje zpětnou vazbu se všemi satelity (vysílá informace na družice, odkud jsou šířeny v&nbsp;navigační zprávě).</p>
<h3>Uživatelský segment</h3>
<p>Uživatelský segment tvoří především stanice jednotlivých přijímačů signálů GPS, jejichž poloha se určuje geometrickým protínáním z&nbsp;měřených vzdáleností mezi satelity a přijímači. Skládá se také z&nbsp;uživatelů a softwaru.</p>
<p>Přesnost přístrojů záleží na počtu přijímaných frekvencí, signálů a korekčních údajů z&nbsp;podpůrného segmentu. V&nbsp;současnosti se prosazují zařízení kombinující příjem z&nbsp;více systémů GNSS najednou (GPS, Glonass, Galileo aj.).</p>
<p>Hlavními součástmi přístrojů uživatelského segmentu jsou anténa (vestavěná, či samostatná) a samotný přijímač (oscilátor, demodulátor, korelátor, fázový článek, paměť, napájení atp.).</p>
<h3>Podpůrný segment</h3>
<p>Podpůrný segment se skládá ze systémů zvyšující v&nbsp;reálném čase základní přesnost GNSS. Lze je dělit v&nbsp;základu na:</p>
<ul>
<li><strong>družicové systémy</strong></li>
<li><strong>pozemní systémy</strong></li>
</ul>
<p><strong>Družicové systémy</strong> zvyšují přesnost pomocí satelitů na geostacionárních drahách. Jde o tzv. SBAS (Satellite Based Augmentation Systems), patří mezi ně např. MSAS (region Japonska), WAAS (region USA) a EGNOS (region Evropy).</p>
<p>Systém <strong>EGNOS</strong> (European Geostationary Navigation Overlay Service) je evropský projekt, který formou diferenciálního signálu poskytuje korekce k signálu GPS. Zpracováním diferenciálního signálu v GNSS přijímači dochází ke zpřesnění určení polohy, řádově v&nbsp;jednotkách metru. V dubnu roku 2009 bylo převedeno veškeré vlastnictví systému EGNOS z Evropské kosmické agentury (ESA) na Evropskou komisi (EK) a oficiálně byl systém spuštěn v říjnu 2009.</p>
<p><strong>Pozemní systémy </strong>se skládají ze stanic permanentně provozovaných přijímačů a jejich sítí. Poskytují výpočet několika možných typů korekcí a distribuují je koncovému uživateli např. přes radio, GSM či internet. Mezi takové sítě patří CZEPOS (Česko), SKPOS (Slovensko), APOS (Rakousko) a SAPOS (Německo). Ne evropské úrovni pak funguje síť permanentních stanic GPS EUREF.</p>

    <hr />
    <ImgCon><Gps /><div>
    <h2>Studijn&iacute; materi&aacute;ly Základy geodézie a GPS</h2>
    <p>T&eacute;ma <strong>GNSS systémy a jejich složení</strong> je souč&aacute;st&iacute; <Link to="/zaklady-geodezie-gps/">studijn&iacute;ch materi&aacute;lů Základy geodézie a GPS (GNSS)</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2. tlačítko) či n&aacute;sleduj&iacute;c&iacute;c&iacute; (3.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/zaklady-geodezie-gps/"><button>Rozcestník geodézie a GPS</button></Link>
    <Link to="/zaklady-geodezie-gps/vypocet-smerniku-rajonu/"><button className="inv">&larr; Výpočet směrníku a rajónu</button></Link>
    <Link to="/zaklady-geodezie-gps/vysilane-signaly-gnss/"><button className="inv">Vysílané signály GNSS &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
